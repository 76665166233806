import React, { useEffect } from "react";

import Hero from "@components/shared/hero";
import { Container, GridContainer, MenuButtonGatsbyLink } from "@util/standard";
import { graphql, PageProps } from "gatsby";
import SEO from "@components/shared/seo";
import LayOut from "@components/layout";
import { Query, SanityTermsPageConnection } from "@graphql-types";
import { useRegionChanged } from "@util/hooks";

interface Data extends Query {
  altRegions: SanityTermsPageConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: any;
}

const Security = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useRegionChanged("lega");

  const sanityTermsPage = props.data.allSanityTermsPage.nodes[0];
  const alternateRegions = props.data.altRegions.nodes.map(
    alt => alt._rawRegion
  );

  return (
    <>
      <SEO
        seoData={sanityTermsPage.seo}
        alternateRegions={alternateRegions}
        slug={props.pageContext.slug}
      />

      <LayOut>
        <Hero singleHeroContent={sanityTermsPage.hero} />
        <Container
          width="100%"
          backgroundColor="babyBlue"
          margin="40px auto auto auto"
        >
          <GridContainer
            gridTemplateColumns="1fr 1fr"
            width="80%"
            margin="auto"
            gridGap="40px 80px"
            padding="70px 0px"
            mobileRepeat={1}
          >
            {sanityTermsPage?.downloads?.map(download => (
              <Container
                key={download?._key}
                backgroundColor="white"
                padding="40px 25px"
                height="auto"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <h3>{download?.title}</h3>
                <p>{download?.description}</p>

                {download?.downloadLink && (
                  <MenuButtonGatsbyLink
                    width="fit-content"
                    margin="0px"
                    padding="10px 25px 10px 25px"
                    color="secondary"
                    href={`${download?.downloadLink?.asset?.url}?dl`}
                  >
                    Download
                  </MenuButtonGatsbyLink>
                )}

                {!download?.downloadLink && download?.link && (
                  <MenuButtonGatsbyLink
                    width="fit-content"
                    margin="0px"
                    padding="10px 25px 10px 25px"
                    color="secondary"
                    href={download?.link.url ?? ""}
                  >
                    Download
                  </MenuButtonGatsbyLink>
                )}
              </Container>
            ))}
          </GridContainer>
        </Container>
      </LayOut>
    </>
  );
};

export default Security;

export const query = graphql`
  query termsQuery($iban: String) {
    allSanityTermsPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        downloads {
          _key
          title
          description
          downloadLink {
            asset {
              originalFilename
              source {
                url
              }
              path
              url
            }
          }
          link {
            internallink
            linktext
            newwindow
            url
          }
        }
      }
    }
    altRegions: allSanityTermsPage {
      nodes {
        _rawRegion(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
